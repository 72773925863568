<template lang="pug">
div.page-wrapper
  v-container.container-content(fluid)
    v-row(class="m-0")
      v-col(cols="12")
        v-row(align="center" justify="start")
          v-col(cols="2")
            v-autocomplete(
              dense
              outlined
              label="Produto"
              return-object
              :items="datasetProdutos"
              item-text="text"
              item-value="value"
              v-model="selectedProduto"
              hide-details
              multiple
            )
              template(v-slot:selection="{ item, index }")
                template(v-if="index === 0")
                  span {{ item.text }}
                template(v-else-if="index === 1")
                  span.grey--text.text-caption.pl-2 (+{{ selectedProduto.length - 1 }} outros)
                  
          v-col(cols="2")
            v-autocomplete(
              dense
              outlined
              label="Estado (UF)"
              return-object
              :items="datasetUF"
              item-text="text"
              item-value="value"
              v-model="selectedUF"
              hide-details
              multiple
            )
              template(v-slot:selection="{ item, index }")
                template(v-if="index === 0")
                  span {{ item.text }}
                template(v-else-if="index === 1")
                  span.grey--text.text-caption.pl-2 (+{{ selectedUF.length - 1 }} outros)

          v-col(cols="2")
            v-autocomplete(
              dense
              outlined
              label="Cidade"
              return-object
              :items="datasetCidades"
              item-text="text"
              item-value="value"
              v-model="selectedCidade"
              hide-details
              multiple
              :disabled="!selectedUF"
            )
              template(v-slot:selection="{ item, index }")
                template(v-if="index === 0")
                  span {{ item.text }}
                template(v-else-if="index === 1")
                  span.grey--text.text-caption.pl-2 (+{{ selectedCidade.length - 1 }} outros)

          v-col(cols="2")
            v-text-field(
              dense
              outlined
              type="date"
              label="Data inicial"
              v-model="dataInicial"
              :rules="dateRules"
              @input="handleDateInput('dataInicial', $event)"
              :max="today"
              hide-details
            )

          v-col(cols="2")
            v-text-field(
              dense
              outlined
              type="date"
              label="Data final"
              v-model="dataFinal"
              :rules="dateRules"
              @input="handleDateInput('dataFinal', $event)"
              :max="today"
              hide-details
            )

          v-col(cols="2")
            v-btn(
              color="primary"
              :disabled="!dataInicial || !dataFinal"
              block
              height="40"
              @click="getData(false)"
            )
              v-icon(left) mdi-magnify
              span Buscar

    v-row(class="mt-0")
      v-col(cols="4")
        dashboard-card(
          color="green"
          icon="mdi-truck-plus"
          title="Entradas"
          :main-value="cardEntradas.mainValue"
          :main-label="cardEntradas.mainLabel"
          :stats="cardEntradas.stats"
          :tableData="cardEntradas.tableData"
        )
      v-col(cols="4")
        dashboard-card(
          color="orange"
          icon="mdi-truck-minus"
          title="Saídas"
          :main-value="cardSaidas.mainValue"
          :main-label="cardSaidas.mainLabel"
          :stats="cardSaidas.stats"
          :tableData="cardSaidas.tableData"
        )
      v-col(cols="4")
        dashboard-card(
          color="blue"
          icon="mdi-silo"
          title="Geral"
          :main-value="cardGeral.mainValue"
          :main-label="cardGeral.mainLabel"
          :stats="cardGeral.stats"
          :tableData="cardGeral.tableData"
        )

    v-row(class="mt-0")
      v-col(cols="8")
        v-card(outlined)
          v-tabs(v-model="tab" color="primary")
            v-tab(key="carga") Cargas
            v-tab(key="tonelada") Toneladas
          v-tabs-items(v-model="tab")
            v-tab-item(key="carga")
              v-card(flat)
                v-card-text
                  apexchart(
                    type="area"
                    :options="cargasChartOptions"
                    :series="chartCargas"
                    height="450"
                  )
            v-tab-item(key="tonelada")
              v-card(flat)
                v-card-text
                  apexchart(
                    type="area"
                    :options="toneladaChartOptions"
                    :series="chartToneladas"
                    height="450"
                  )
      v-col(cols="4" class="d-flex flex-column")
        progress-card(
          outlined 
          class="mb-4 flex-grow-1"
          cardTitle="Média da classificação de Entradas"
          idColor="green"
          :dataset="cardDescontosEntradas"
          )
        progress-card(
          outlined 
          class="flex-grow-1"
          cardTitle="Média da classificação de Saídas"
          idColor="amber"
          :dataset="cardDescontosSaidas"
          )
</template>

<script>
import DashboardCard from "@/components/DashboardCard.vue";
import ProgressCard from "@/components/ProgressCard.vue";
import csapi from "@/api/csapi";
import { mapMutations } from "vuex";

export default {
  name: "Romaneios",
  components: {
    DashboardCard,
    ProgressCard
  },
  data() {
    const today = new Date();
    const lastYear = new Date();
    lastYear.setFullYear(today.getFullYear() - 1);
    lastYear.setMonth(11);
    lastYear.setDate(15);

    return {
      today,
      lastYear,
      datasetProdutos: [],
      datasetUF: [],
      datasetCidades: [],
      chartToneladas: [],
      chartCargas: [],
      cardEntradas: {
        mainValue: "-",
        mainLabel: "cargas",
        stats: [],
        tableData: []
      },
      cardSaidas: {
        mainValue: "-",
        mainLabel: "cargas",
        stats: [],
        tableData: []
      },
      cardGeral: {
        mainValue: "-",
        mainLabel: "cargas",
        stats: [],
        tableData: []
      },
      cardDescontosEntradas: [],
      cardDescontosSaidas: [],
      selectedProduto: null,
      selectedUF: null,
      selectedCidade: null,
      dataInicial: lastYear.toISOString().split("T")[0],
      dataFinal: today.toISOString().split("T")[0],
      testModel: null,
      tab: "carga",
      exampleData: [
        {
          name: "Entradas",
          data: Array.from({ length: 30 }, (_, i) => {
            const date = new Date(2024, 0, i + 1);
            return {
              x: date.toISOString().split("T")[0],
              y: Math.floor(Math.random() * (120 - 30) + 30)
            };
          })
        },
        {
          name: "Saídas",
          data: Array.from({ length: 30 }, (_, i) => {
            const date = new Date(2024, 0, i + 1);
            return {
              x: date.toISOString().split("T")[0],
              y: Math.floor(Math.random() * (80 - 20) + 20)
            };
          })
        }
      ],
      exampleColors: ["#B0BEC5", "#37474F"],
      dateRules: [v => !!v || "Data é obrigatória"]
    };
  },

  computed: {
    numberFormatter() {
      return new Intl.NumberFormat("pt-BR");
    },

    decimalFormatter() {
      return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      });
    },

    formattedChartData() {
      return this.exampleData;
    },

    chartColors() {
      return ["#4caf50", "#ff9800"];
    },

    baseChartOptions() {
      return {
        grid: {
          padding: {
            right: 10,
            bottom: 20,
            left: 40
          }
        },
        chart: {
          type: "line",
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "center",
          fontSize: "14px"
        },
        colors: ["#4caf50", "#ff9800"],
        stroke: {
          curve: "monotoneCubic",
          width: 2
        },
        xaxis: {
          type: "date",
          tickAmount: 5,
          labels: {
            show: true,
            rotate: 0,
            formatter: function(value) {
              const date = new Date(value);
              const day = String(date.getDate()).padStart(2, "0");
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const year = date.getFullYear();
              return `${day}/${month}/${year}`;
            },
            style: {
              colors: "#666",
              fontSize: "12px"
            },
            datetimeUTC: false,
            showAlways: true,
            tickPlacement: "on"
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          tooltip: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        }
      };
    },

    cargasChartOptions() {
      return {
        ...this.baseChartOptions,
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#666",
              fontSize: "12px"
            },
            formatter: value => this.formatNumber(value, "cargas")
          }
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd/MM/yyyy"
          },
          y: {
            formatter: value => this.formatNumber(value, "cargas")
          }
        }
      };
    },

    toneladaChartOptions() {
      return {
        ...this.baseChartOptions,
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#666",
              fontSize: "12px"
            },
            formatter: value => this.formatNumber(value, "ton")
          }
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd/MM/yyyy"
          },
          y: {
            formatter: value => this.formatNumber(value, "ton")
          }
        }
      };
    }
  },

  methods: {
    ...mapMutations("app", ["setTitle"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    formatChartData(data, type) {
      const { datas, entradas, saidas } = data;
      return [
        {
          name: "Entradas",
          data: datas.map((date, index) => ({
            x: date,
            y: type === "carga" ? entradas[index] : Number((entradas[index] / 1000).toFixed(1))
          }))
        },
        {
          name: "Saídas",
          data: datas.map((date, index) => ({
            x: date,
            y: type === "carga" ? saidas[index] : Number((saidas[index] / 1000).toFixed(1))
          }))
        }
      ];
    },

    formatCardData(detalhe) {
      if (!detalhe || !Array.isArray(detalhe) || !detalhe.length) {
        return {
          mainValue: "-",
          mainLabel: "cargas",
          stats: [
            { value: "0", label: "toneladas" },
            { value: "0", label: "sacas" }
          ],
          tableData: []
        };
      }

      // Calculate totals
      const totals = detalhe.reduce(
        (acc, curr) => ({
          cargas: acc.cargas + (curr.cargas || 0),
          toneladas: acc.toneladas + (curr.movimentacaoTon || 0),
          sacas: acc.sacas + (curr.movimentacaoSacas || 0)
        }),
        { cargas: 0, toneladas: 0, sacas: 0 }
      );

      const numberFormatter0 = new Intl.NumberFormat("pt-BR");
      const numberFormatter1 = new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      });

      return {
        mainValue: numberFormatter0.format(totals.cargas),
        mainLabel: "cargas",
        stats: [
          { value: numberFormatter1.format(totals.toneladas), label: "toneladas" },
          { value: numberFormatter0.format(Math.round(totals.sacas)), label: "sacas" }
        ],
        tableData: detalhe.map(item => ({
          descricao: `${item.operacao || ""} - ${item.complemento || ""}`,
          cargas: item.cargas || 0,
          toneladas: item.movimentacaoTon || 0,
          sacas: Math.round(item.movimentacaoSacas || 0),
          percentual: item.percentual || 0
        }))
      };
    },

    formatDescontosData(descontos) {
      const colorMap = {
        Umidade: "light-blue",
        Impurezas: "amber",
        Ardidos: "orange",
        Avariados: "red",
        Esverdeados: "green",
        Quebrados: "purple"
      };

      return Object.entries(descontos)
        .filter(([key]) => key !== "Outros")
        .map(([label, value]) => ({
          label,
          value: Number(value.toFixed(2)),
          color: colorMap[label]
        }));
    },

    async getProdutos() {
      this.showLoader("Carregando dados...");
      try {
        const response = await csapi.get("/v1/datawarehouse/romaneios-mov/produtos");

        this.datasetProdutos = response.data.map(produto => ({
          text: produto,
          value: produto
        }));
      } catch (error) {
        this.$toast.error("Erro ao carregar dados");
      } finally {
        this.hideLoader();
      }
    },

    async getUF() {
      this.showLoader("Carregando dados...");
      try {
        const response = await csapi.get("/v1/datawarehouse/romaneios-mov/ufs");

        this.datasetUF = response.data.map(uf => ({
          text: uf,
          value: uf
        }));
      } catch (error) {
        this.$toast.error("Erro ao carregar dados");
      } finally {
        this.hideLoader();
      }
    },

    async getCidades() {
      if (!this.selectedUF?.length) {
        this.datasetCidades = [];
        return;
      }

      this.showLoader("Carregando dados...");
      try {
        const response = await csapi.get("/v1/datawarehouse/romaneios-mov/cidades", {
          UF: this.selectedUF?.map(item => item.value) || []
        });

        this.datasetCidades = response.data.map(cidade => ({
          text: cidade,
          value: cidade
        }));
      } catch (error) {
        this.$toast.error("Erro ao carregar dados");
      } finally {
        this.hideLoader();
      }
    },

    async getData(useDefaultParams = false) {
      this.showLoader("Carregando dados...");
      try {
        const requestParams = useDefaultParams
          ? {
              DtLancRomaneioInicial: this.lastYear.toISOString().split("T")[0],
              DtLancRomaneioFinal: this.today.toISOString().split("T")[0]
            }
          : {
              Produto: this.selectedProduto?.map(item => item.value) || [],
              UF: this.selectedUF?.map(item => item.value) || [],
              Cidade: this.selectedCidade?.map(item => item.value) || [],
              DtLancRomaneioInicial: this.dataInicial,
              DtLancRomaneioFinal: this.dataFinal
            };

        const response = await csapi.get(
          "/v1/datawarehouse/romaneios-mov/movimentacao",
          requestParams
        );

        // gráficos
        this.chartCargas = this.formatChartData(response.data.graficoCarga, "carga");
        this.chartToneladas = this.formatChartData(response.data.graficoPesagem, "peso");

        // cards
        this.cardEntradas = this.formatCardData(response.data.detalheEntrada);
        this.cardSaidas = this.formatCardData(response.data.detalheSaida);
        this.cardGeral = this.formatCardData(response.data.detalheGeral);

        // descontos
        this.cardDescontosEntradas = this.formatDescontosData(response.data.descontosEntrada);
        this.cardDescontosSaidas = this.formatDescontosData(response.data.descontosSaida);
      } catch (error) {
        this.$toast.error("Erro ao carregar dados");
      } finally {
        this.hideLoader();
      }
    },

    formatDateBR(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    handleDateInput(field, value) {
      this[field] = value;
    },

    formatDate(date) {
      if (!date) return null;
      if (typeof date === "string") {
        if (date.match(/^\d{4}-\d{2}-\d{2}$/)) return date;
      }
      return new Date(date).toISOString().split("T")[0];
    },

    formatNumber(value, unit) {
      if (value === null || value === undefined || isNaN(Number(value))) {
        return "-";
      }
      const unit_suffix = unit === "cargas" ? " cargas" : " ton";
      return new Intl.NumberFormat("pt-BR").format(Number(value)) + unit_suffix;
    }
  },

  mounted() {
    this.getData(true);
    this.getProdutos();
    this.getUF();
    this.setTitle("Acompanhamento de Safra");
  },

  watch: {
    dataInicial(val) {
      if (!val) return;
      const date = new Date(val);
      if (!isNaN(date.getTime())) {
        this.dataInicial = this.formatDate(date);
      }
    },

    dataFinal(val) {
      if (!val) return;
      const date = new Date(val);
      if (!isNaN(date.getTime())) {
        this.dataFinal = this.formatDate(date);
      }
    },

    selectedUF: {
      handler(val) {
        this.selectedCidade = null;
        this.datasetCidades = [];

        if (val?.length) {
          this.getCidades();
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.page-wrapper {
  height: calc(100vh - 64px);
  overflow: hidden;
  position: relative;
}

.container-content {
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  scrollbar-width: thin;
}

.v-icon.large {
  font-size: 48px;
}

.fill-height {
  height: 100%;
  margin: 0;
}

.flex-grow-1 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.v-card {
  display: flex;
  flex-direction: column;
}
</style>
